import Box from '@mui/material/Box'

export default function AssistantMessage({ content }) {
  if (content.match(/```sql/i)) {
    content = content.replace(/```sql/g, '<code class="language-sql">')
    content = content.replace(/```/g, '</code>')
  }

  return (
    <Box sx={{ py: 3 }}>
      <pre style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: content }}></pre>
    </Box>
  )
}

//
