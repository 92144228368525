import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import CopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import HistoryIcon from '@mui/icons-material/History'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import { confirm } from 'components/Confirm'
import HistoryDialog from 'components/HistoryDialog'
import { updateDoc } from 'lib/firestore'

export default function DetailPageMenuList({ item, children }) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const collectionName = location.pathname.split('/')[1]

  return (
    <Stack direction="row" spacing={2} sx={{ flex: 1, ml: 2, height: '32px' }}>
      <DetailPageMenuButton
        onClick={() => navigate('../new', { state: { item } })}
        startIcon={<CopyIcon size={12} />}
        disabled={!item}
      >
        複製
      </DetailPageMenuButton>
      <DetailPageMenuButton
        onClick={async () => {
          if (
            await confirm({
              title: '削除してもよろしいですか？',
              body: '一度削除すると復元することはできません。',
            })
          ) {
            await updateDoc(`${collectionName}/${item.id}`, { deleted: true })
            navigate('../')
          }
        }}
        startIcon={<DeleteIcon size={12} />}
        disabled={!item}
      >
        削除
      </DetailPageMenuButton>
      <DetailPageMenuButton
        startIcon={<HistoryIcon size={12} />}
        disabled={!item || item.createdAt.getTime() === item.updatedAt.getTime()}
        onClick={() => setDialogOpen(true)}
      >
        編集履歴
      </DetailPageMenuButton>
      {dialogOpen && <HistoryDialog onClose={() => setDialogOpen(false)} />}
      {children}
    </Stack>
  )
}

export function DetailPageMenuButton({ children, ...props }) {
  return (
    <Button color="inherit" size="small" sx={{ borderRadius: 100 }} {...props}>
      {children}
    </Button>
  )
}
