import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import MonacoEditor from '@monaco-editor/react'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { CONDITION_TYPE } from 'entities/segment'

import { getError } from 'components/Form'

import OpenAIDialog from './OpenAI/Dialog'

const { Parser } = require('node-sql-parser')

export const defaultSql = { type: CONDITION_TYPE.SQL, sql: null }

export default function SqlField({ name }) {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext()
  const [openDialog, setOpenDialog] = useState(false)
  const fieldName = `${name}.sql`
  const defaultValue =
    getValues(fieldName) || `select\n\tSERIAL_ID AS USER_ID\nfrom\n\tims.M_AD_NIKKEI_ID\nlimit 1000`
  const error = getError(errors, fieldName)
  const value = watch(fieldName)

  useEffect(() => {
    register(fieldName, {
      validate: v => {
        try {
          const parser = new Parser()
          const options = { database: 'bigquery' }
          const ast = parser.astify(v, options)
          parser.sqlify(ast, options)
          // 異常終了しなければ SQL 構文として正しいと判断し true を返す
          return true
        } catch (error) {
          return error.message
        }
      },
    })
    setValue(fieldName, defaultValue)
  }, [register, setValue, fieldName, defaultValue])

  const handleChange = value => {
    setValue(fieldName, value, { shouldDirty: true })
  }

  const handleGenerate = sql => {
    console.log(sql)
    setValue(fieldName, sql, { shouldDirty: true })
  }

  return (
    <Box sx={{ flex: 1 }}>
      <Box sx={{ height: '320px', position: 'relative' }}>
        <MonacoEditor
          name={fieldName}
          options={{ fontSize: 14, scrollBeyondLastLine: false }}
          language="sql"
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
        />
        <Box sx={{ position: 'absolute', bottom: 16, right: 16 }}>
          <IconButton onClick={() => setOpenDialog(true)}>
            <Tooltip title="日経AIBotに相談する">
              <AutoAwesomeIcon />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
      {openDialog && (
        <OpenAIDialog onGenerate={handleGenerate} onClose={() => setOpenDialog(false)} />
      )}
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </Box>
  )
}
