import Box from '@mui/material/Box'

export default function UserMessage({ content }) {
  return (
    <Box sx={{ py: 3, display: 'flex', justifyContent: 'flex-end' }}>
      <Box sx={{ p: 2, background: '#eee', borderRadius: '16px' }}>
        <pre style={{ margin: 0 }}>{content}</pre>
      </Box>
    </Box>
  )
}
