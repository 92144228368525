import CommonListItem from 'components/CommonListItem'
import useSegment from 'hooks/useSegment'

export default function SegmentListItem({ item, group, editable, onClick, onDelete, onDuplicate }) {
  const { items } = useSegment()
  const hasDependencies = items.some(x => x.condition.filters.some(y => item.id === y.segment?.id))

  return (
    <CommonListItem
      item={item}
      group={group}
      editable={editable && !hasDependencies}
      onClick={onClick}
      onDelete={onDelete}
      onDuplicate={onDuplicate}
    />
  )
}
