import dayjs from 'dayjs'

export const ID_TYPE = Object.freeze({
  SERIAL: { value: 'serial', label: 'シリアルID' },
  ATLAS: { value: 'atlas', label: 'Atlas ID' },
})

export const TRIGGERS = Object.freeze([
  {
    name: 'dynamic',
    label: '動的',
    description: '実行時に最新のデータを取得します',
  },
  {
    name: 'static',
    label: '静的',
    description: '作成時の結果を取得します',
  },
])

export const CONDITION_TYPE = Object.freeze({
  TABLE: 'table',
  SEGMENT: 'segment',
  SQL: 'sql',
})

export const OPERATOR = Object.freeze({
  IS_NULL: 'IS NULL',
  IS_NOT_NULL: 'IS NOT NULL',
  IS_EMPTY: '= ""',
  IS_NOT_EMPTY: '!= ""',
  IN: 'IN',
  NOT_IN: 'NOT IN',
})

export const NO_VALUE_OPERATORS = Object.freeze([
  OPERATOR.IS_NULL,
  OPERATOR.IS_NOT_NULL,
  OPERATOR.IS_EMPTY,
  OPERATOR.IS_NOT_EMPTY,
])

export const operators = Object.freeze([
  { label: 'と一致 (=)', value: '=', types: '*' },
  { label: 'と一致しない (!=)', value: '!=', types: '*' },
  { label: 'の中に存在 (IN)', value: OPERATOR.IN, types: ['STRING', 'INTEGER', 'BOOLEAN'] },
  {
    label: 'の中に存在しない (NOT IN)',
    value: OPERATOR.NOT_IN,
    types: ['STRING', 'INTEGER', 'BOOLEAN'],
  },
  {
    label: 'より大きい (>)',
    value: '>',
    types: ['BIGNUMERIC', 'INTEGER', 'FLOAT', 'DATE', 'DATETIME', 'TIME', 'TIMESTAMP'],
  },
  {
    label: '以上 (>=)',
    value: '>=',
    types: ['BIGNUMERIC', 'INTEGER', 'FLOAT', 'DATE', 'DATETIME', 'TIME', 'TIMESTAMP'],
  },
  {
    label: 'より小さい (<)',
    value: '<',
    types: ['BIGNUMERIC', 'INTEGER', 'FLOAT', 'DATE', 'DATETIME', 'TIME', 'TIMESTAMP'],
  },
  {
    label: '以下 (<=)',
    value: '<=',
    types: ['BIGNUMERIC', 'INTEGER', 'FLOAT', 'DATE', 'DATETIME', 'TIME', 'TIMESTAMP'],
  },
  { label: 'NULL (IS NULL)', value: OPERATOR.IS_NULL, types: '*' },
  { label: 'NULL ではない (IS NOT NULL)', value: OPERATOR.IS_NOT_NULL, types: '*' },
  { label: '空文字 (="")', value: OPERATOR.IS_EMPTY, types: ['STRING'] },
  { label: '空文字ではない (!="")', value: OPERATOR.IS_NOT_EMPTY, types: ['STRING'] },
])

export const getOperators = type => {
  if (!type) {
    return operators.map(({ label, value }) => ({ label, value }))
  }
  return operators
    .filter(x => x.types === '*' || x.types.includes(type))
    .map(({ label, value }) => ({ label, value }))
}

// 行動ログテーブルに対して 7 日よりも大きい期間の場合、バッヂクエリで実行
export const hasBatchQuery = segment => {
  return segment.condition.filters
    .filter(x => Boolean(x.from))
    .some(x => {
      const to = x.to !== '' ? dayjs(x.to) : dayjs()
      const diff = to.diff(dayjs(x.from), 'day')
      return diff > 7
    })
}
