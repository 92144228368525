import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import WarningIcon from '@mui/icons-material/WarningTwoTone'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'

import ListItem from 'components/CommonListItem'
import { confirm } from 'components/Confirm'
import GroupFilter, { filter as filterWithGroup } from 'components/GroupFilter'
import { showSnackbar } from 'components/Snackbar'
import Sort from 'components/Sort'
import TagFilter, { filter as filterWithTags } from 'components/TagFilter'
import useLocalStorage from 'hooks/useLocalStorage'
import useAuth from 'lib/auth'
import { orderBy, updateDoc } from 'lib/firestore'
import { useDocs, where } from 'lib/firestore'

import { DELIVERY_STATUS } from './Detail'

export default function TargetMailList() {
  const [filterCondition, setFilterCondition] = useLocalStorage('filterConditionTargetMailList', {
    group: '',
    tags: [],
  })
  const navigate = useNavigate()
  const [tags, setTags] = useState(filterCondition.tags)
  const [group, setGroup] = useState(filterCondition.group)
  const [order, setOrder] = useState(['createdAt', 'desc'])
  const { items: groups } = useDocs('groups')
  const { user } = useAuth()
  const { items } = useDocs('target-mails', where('deleted', '==', false), orderBy(...order))

  const targetMails = useMemo(() => {
    let _items = [...items]
    _items = filterWithGroup(_items, group, user)
    _items = filterWithTags(_items, tags)
    return _items
  }, [items, tags, group, user])

  const handleDuplicate = async item => {
    navigate('./new', { state: { item } })
  }

  const handleDelete = async item => {
    if (
      await confirm({
        title: 'メールを削除してもよろしいですか？',
        body: '一度削除すると復元することはできません。',
      })
    ) {
      updateDoc(`target-mails/${item.id}`, { deleted: true }).catch(() =>
        showSnackbar('メールの削除に失敗しました')
      )
    }
  }

  const handleChangeGroup = newValue => {
    setGroup(newValue)
    setFilterCondition({ ...filterCondition, group: newValue })
  }

  const handleChangeTags = newValue => {
    setTags(newValue)
    setFilterCondition({ ...filterCondition, tags: newValue })
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
        <GroupFilter value={group} onChange={handleChangeGroup} />
        <TagFilter value={tags} onChange={handleChangeTags} />
        <Sort
          value={order}
          additionalOptions={[
            { label: '送信完了日 降順', value: 'sentAt,desc' },
            { label: '送信完了日 昇順', value: 'sentAt,asc' },
            { label: '送信予定日 降順', value: 'sendAt,desc' },
            { label: '送信予定日 昇順', value: 'sendAt,asc' },
          ]}
          onChange={setOrder}
        />
      </Box>
      <Box>
        {targetMails.map(x => (
          <ListItem
            key={x.id}
            item={x}
            actionSecondary={<ActionStatus item={x} />}
            group={groups.find(y => y.id === x.group)}
            editable={!x.group || user.groups?.includes(x.group)}
            onClick={() => navigate(`./${x.id}`)}
            onDelete={() => handleDelete(x)}
            onDuplicate={() => handleDuplicate(x)}
          />
        ))}
      </Box>
      <Fab onClick={() => navigate('./new')}>
        <AddIcon />
      </Fab>
    </Box>
  )
}

const ActionStatus = ({ item }) => {
  if (!item.approval.group || !item.approval.admin) {
    return (
      <Box>
        <Typography sx={{ fontSize: 12, color: 'text.secondary' }}>承認待ち</Typography>
      </Box>
    )
  }

  switch (item.latestDelivery?.state) {
    case DELIVERY_STATUS.SUCCESS:
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <CheckIcon color="primary" sx={{ fontSize: 14 }} />
          <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: 'primary.main' }}>
            送信済み
          </Typography>
          <Typography sx={{ fontSize: 12, color: 'text.secondary' }}>
            {dayjs(item.latestDelivery.sentAt).format('YYYY-MM-DD HH:mm')}
          </Typography>
        </Box>
      )

    case DELIVERY_STATUS.ERROR:
      return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <WarningIcon color="error" sx={{ fontSize: 16 }} />
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: 'error.main' }}>
            送信に失敗しました
          </Typography>
        </Box>
      )

    default:
      return (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography sx={{ fontSize: 12, color: 'text.secondary' }}>送信予定日時</Typography>
          <Typography sx={{ fontSize: 12, color: 'text.secondary' }}>
            {dayjs(item.sendAt).format('YYYY-MM-DD HH:mm')}
          </Typography>
        </Box>
      )
  }
}
