import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { TRIGGERS } from 'entities/segment'

import { Autocomplete } from 'components/Form'
import { filter } from 'components/GroupFilter'
import useAuth from 'lib/auth'
import { useDocs, where } from 'lib/firestore'
import { numberWithCammas } from 'utils'

const createSecondaryText = segment => {
  const elements = []

  const triggerName = TRIGGERS.find(x => x.name === segment.trigger).label
  elements.push(triggerName)

  if (segment.tags.length > 0) {
    const tagNames = segment.tags.join('/')
    elements.push(tagNames)
  }

  return elements.join('、')
}

const createTertiaryText = table => {
  const dateFormatted = dayjs(new Date(Number(table.creationTime))).format('YYYY-MM-DD HH:mm')
  const numUsers = numberWithCammas(table.numRows)
  return `最終実行日時: ${dateFormatted}、ユーザー数: ${numUsers} 名`
}

const findItem = (items, id) => {
  return items.find(x => x.id === id) || null
}

export default function SegmentField({ name, disabled }) {
  const { user } = useAuth()
  const { items } = useDocs('segments', where('deleted', '==', false))
  const filteredItems = filter(items, '', user)
    .filter(x => {
      // 静的セグメントで結果が出力されていないセグメントは対象外
      if (x.trigger === 'static' && !x.result) return false
      return true
    })
    .map(x => ({ id: x.id, name: x.name }))

  return (
    <Autocomplete
      name={name}
      label="セグメント"
      rules={{ required: true }}
      disabled={disabled}
      fullWidth
      options={filteredItems}
      optionKeys={['id', 'name']}
      getOptionLabel={x => {
        const item = findItem(items, x.id)
        if (!item) return ''
        if (Boolean(item.result?.table)) {
          return `${item.name} (${createTertiaryText(item.result.table)})`
        }
        return item.name
      }}
      renderOption={(props, x) => {
        const item = findItem(items, x.id)
        if (!item) return null
        return (
          <li {...props} key={x.id} style={{ flexDirection: 'column', alignItems: 'start' }}>
            {item.name}
            <Typography variant="caption" color="textSecondary">
              {createSecondaryText(item)}
            </Typography>
            {Boolean(item.result?.table) && (
              <Typography variant="caption" color="textSecondary">
                {createTertiaryText(item.result.table)}
              </Typography>
            )}
          </li>
        )
      }}
    />
  )
}
