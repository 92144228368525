import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'

import { orderBy, useDocs } from 'lib/firestore'
import dayjs from 'dayjs'
import { DELIVERY_STATUS } from './Detail'

export default function DeliveryHistory() {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(null)
  const navigate = useNavigate()
  const params = useParams()
  const { items } = useDocs(`target-mails/${params.id}/deliveries`, orderBy('createdAt', 'desc'))

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionProps={{ onExited: () => navigate('../') }}
        sx={{ '& .MuiDialog-paper': { bgcolor: 'background.default' } }}
      >
        <AppBar position="fixed" color="primary" elevation={0}>
          <Toolbar>
            <IconButton color="inherit" onClick={() => setOpen(false)} edge="start" sx={{ mr: 2 }}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flex: 1 }}>
              配信履歴
            </Typography>
          </Toolbar>
          <Divider />
        </AppBar>
        <Toolbar />
        <Box sx={{ p: 5 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 400 }}>
              <TableHead>
                <TableRow>
                  <TableCell>送信予約日時</TableCell>
                  <TableCell>ステータス</TableCell>
                  <TableCell>配信モード</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map(x => (
                  <DownloadHistoryItem key={x.id} item={x} onSelect={() => setSelected(x)} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Dialog>
      {Boolean(selected) && (
        <DeliveryDetailDialog item={selected} onClose={() => setSelected(null)} />
      )}
    </>
  )
}

const DownloadHistoryItem = ({ item, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const color = (() => {
    switch (item.state) {
      case DELIVERY_STATUS.ERROR:
        return 'error.main'
      case DELIVERY_STATUS.SUCCESS:
        return 'success.main'
      default:
        return 'inherit'
    }
  })()

  return (
    <>
      <TableRow sx={{ '&:last-child .MuiTableCell-root': { border: 0 } }}>
        <TableCell sx={{ color }}>{dayjs(item.sendAt).format('YYYY-MM-DD HH:mm')}</TableCell>
        <TableCell sx={{ color }}>{item.state}</TableCell>
        <TableCell sx={{ color }}>
          {item.mode === 'PRODUCTION' ? '本番配信' : 'テスト配信'}
        </TableCell>
        <TableCell align="right" sx={{ py: 1 }}>
          <IconButton size="small" onClick={e => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setAnchorEl(null)
                onSelect()
              }}
              disabled={[DELIVERY_STATUS.PENDING, DELIVERY_STATUS.PROCESSING].includes(item.state)}
            >
              <ListItemText primary="詳細を見る" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  )
}
const DeliveryDetailDialog = ({ item, onClose }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{ onExited: onClose }}
      maxWidth="lg"
    >
      <DialogTitle>配信詳細</DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ px: 2, borderRadius: '4px', bgcolor: 'grey.300' }}>
          <pre
            style={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              paddingTop: '16px',
              paddingBottom: '16px',
            }}
          >
            {JSON.stringify(item.result, null, 2)}
          </pre>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="text" onClick={() => setOpen(false)}>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  )
}
