import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import { orderBy, useDocs } from 'lib/firestore'
import dayjs from 'dayjs'

export default function IMSHistory() {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const params = useParams()
  const { items } = useDocs(`segments/${params.id}/ims-jobs`, orderBy('sendAt', 'desc'))

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{ onExited: () => navigate('../') }}
      sx={{ '& .MuiDialog-paper': { bgcolor: 'background.default' } }}
    >
      <AppBar position="fixed" color="primary" elevation={0}>
        <Toolbar>
          <IconButton color="inherit" onClick={() => setOpen(false)} edge="start" sx={{ mr: 2 }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flex: 1 }}>
            IMS 連携履歴
          </Typography>
        </Toolbar>
        <Divider />
      </AppBar>
      <Toolbar />
      <Box sx={{ p: 5 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }}>
            <TableHead>
              <TableRow>
                <TableCell>ユーザー</TableCell>
                <TableCell>外部リスト所有グループ</TableCell>
                <TableCell>外部リスト名</TableCell>
                <TableCell>外部リスト利用用途</TableCell>
                <TableCell>ステータス</TableCell>
                <TableCell>実行日時</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map(x => (
                <IMSHistoryItem key={x.id} item={x} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Dialog>
  )
}

const IMSHistoryItem = ({ item }) => {
  return (
    <TableRow sx={{ '&:last-child .MuiTableCell-root': { border: 0 } }}>
      <TableCell>{item.createdBy.email}</TableCell>
      <TableCell>{item.external_list_owner_group_id}</TableCell>
      <TableCell>{item.listName}</TableCell>
      <TableCell>{item.listPurpose}</TableCell>
      <TableCell>{item.state}</TableCell>
      <TableCell>
        {Boolean(item.sendAt) ? dayjs(item.sendAt).format('YYYY-MM-DD HH:mm') : ''}
      </TableCell>
    </TableRow>
  )
}
