import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'

import { Form, SubmitButton, TextField } from 'components/Form'
import { httpsCallable } from 'lib/functions'
import UserMessage from './UserMessage.js'
import AssistantMessage from './AssistantMessage.js'

export default function OpenAIDialog({ item, onGenerate, onClose }) {
  const [open, setOpen] = useState(false)
  const [chatHistories, setChatHistories] = useState([])
  const form = useForm({ defaultValues: item })
  const messagesEndRef = useRef(null)

  useEffect(() => {
    setOpen(true)
  }, [])

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [chatHistories])

  const onSubmit = values => {
    form.reset()
    const userMessage = { role: 'user', content: values.message }
    setChatHistories(x => [...x, userMessage])
    return httpsCallable('callable-openai-getopenairesponse')([...chatHistories, userMessage])
      .then(res => {
        const assistantMessage = { role: 'assistant', content: res.data }
        setChatHistories(x => [...x, assistantMessage])
      })
      .catch(e => console.log(`ERROR: ${e.message}`))

    // TODO: 生成 AI のレスポンス(SQL)を onGenerate に渡す
    //onGenerate('SELECT * FROM users')
    //setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{ onExited: onClose }}
      fullWidth
      maxWidth="xl"
    >
      <Form form={form}>
        <DialogTitle>日経AIBotに相談</DialogTitle>
        <Stack
          sx={{
            padding: 5,
            height: '40vh',
            overflow: 'auto',
            '& .MuiBox-root:not(:last-child)': {
              borderBottom: theme => `1px solid ${theme.palette.grey[300]}`,
            },
          }}
        >
          {chatHistories.map((x, index) =>
            x.role === 'user' ? (
              <UserMessage key={index} content={x.content} />
            ) : (
              <AssistantMessage key={index} content={x.content} />
            )
          )}
          <Box ref={messagesEndRef} sx={{ height: '1px' }} />
        </Stack>
        <Stack spacing={2} sx={{ px: 3, py: 2 }}>
          <TextField
            name="message"
            label="日経AIBotにメッセージを送信する"
            multiline
            rows={5}
            fullWidth
          />
        </Stack>
        <DialogActions>
          <Button variant="text" onClick={() => setOpen(false)}>
            閉じる
          </Button>
          <SubmitButton color="primary" onClick={form.handleSubmit(onSubmit)}>
            相談する
          </SubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
