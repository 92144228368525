import { useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { CONDITION_TYPE, ID_TYPE } from 'entities/segment'

import { Select, TextField } from 'components/Form'
import useSegment from 'hooks/useSegment'

import DefaultQueryField from './DefaultQueryField'
import SegmentField, { defaultSegment } from './SegmentField'
import SqlField, { defaultSql } from './SqlField'
import TableFieldGroup, { defaultTable } from './TableFieldGroup'

export default function Condition({ name }) {
  const [appendTarget, setAppendTarget] = useState(null)
  const [editTarget, setEditTarget] = useState(null)
  const { control, watch, formState } = useFormContext()
  const { fields, append, insert, remove } = useFieldArray({ control, name: `${name}.filters` })
  const [condition, idType] = watch([name, 'idType'])
  const params = useParams()
  const { items } = useSegment()
  const hasGrandParentSegment =
    params.id &&
    items
      .filter(x => x.condition.filters.some(y => params.id === y.segment?.id)) // 選択中のセグメントを子に持つ
      .some(x => items.some(y => y.condition.filters.some(z => x.id === z.segment?.id))) // 他のセグメントを親に持つ

  const handleDuplicate = () => {
    insert(editTarget.index + 1, condition.filters[editTarget.index])
    setEditTarget(null)
  }

  const handleRemove = index => {
    remove(index)
    setEditTarget(null)
  }

  const handleAppendTable = () => {
    append(defaultTable)
    if (appendTarget) {
      setAppendTarget(null)
    }
  }

  const handleAppendSegment = () => {
    append(defaultSegment)
    if (appendTarget) {
      setAppendTarget(null)
    }
  }

  const handleAppendSql = () => {
    append(defaultSql)
    if (appendTarget) {
      setAppendTarget(null)
    }
  }

  return (
    <Box>
      {condition.filters.length > 0 &&
        fields.map((item, index) => (
          <Box key={item.id} sx={{ display: 'flex', alignItems: 'start', mb: 2 }}>
            <Box sx={{ width: 88, height: 48, display: 'flex', alignItems: 'center' }}>
              {index === 0 ? (
                <Typography sx={{ fontWeight: 'bold' }}>条件</Typography>
              ) : (
                <Select
                  name={`${name}.operator`}
                  disabled={index > 1}
                  sx={{
                    width: 80,
                    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': { borderWidth: 0 },
                  }}
                >
                  <MenuItem value="AND">AND</MenuItem>
                  <MenuItem value="OR">OR</MenuItem>
                </Select>
              )}
            </Box>
            <ConditionFilter name={`${name}.filters.${index}`} data={condition.filters[index]} />
            <Box>
              <IconButton
                onClick={e => setEditTarget({ index, element: e.currentTarget })}
                sx={{ mt: '4px' }}
              >
                <MoreVertIcon />
              </IconButton>

              <Popover
                open={Boolean(editTarget)}
                anchorEl={Boolean(editTarget) && editTarget.element}
                onClose={() => setEditTarget(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <List sx={{ minWidth: 80 }}>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => handleDuplicate()}>
                      <ListItemText primary="複製" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => handleRemove(editTarget.index)}>
                      <ListItemText primary="削除" />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
            </Box>
          </Box>
        ))}
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Button
          variant="text"
          startIcon={<AddIcon />}
          onClick={e =>
            hasGrandParentSegment ? handleAppendTable() : setAppendTarget(e.currentTarget)
          }
        >
          条件を追加
        </Button>
        <Popover
          open={Boolean(appendTarget)}
          anchorEl={appendTarget}
          onClose={() => setAppendTarget(null)}
        >
          <List sx={{ minWidth: 80 }}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleAppendTable()}>
                <ListItemText primary="テーブル条件を追加" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleAppendSegment()}>
                <ListItemText primary="セグメントを追加" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleAppendSql()}>
                <ListItemText primary="SQL を手入力" />
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
      </Box>
      {idType === ID_TYPE.SERIAL.value && <DefaultQueryField />}
      <Box sx={{ mb: 2 }}>
        <TextField
          name={`${name}.limit`}
          label="上限人数"
          fullWidth
          type="number"
          disabled={condition.filters.some(x => x.type === CONDITION_TYPE.SQL)}
        />
      </Box>
      {formState.errors[name] && <FormHelperText error>不完全な条件があります</FormHelperText>}
    </Box>
  )
}

const ConditionFilter = ({ name, data }) => {
  switch (data.type) {
    case CONDITION_TYPE.TABLE:
      return <TableFieldGroup name={name} />
    case CONDITION_TYPE.SEGMENT:
      return <SegmentField name={name} />
    case CONDITION_TYPE.SQL:
      return <SqlField name={name} />
    default:
      return null
  }
}
